
import Bar from "../components/TopBanner.vue";
import Nav from "./partials/nav";
import Footer from "./partials/footer";
export default {
  components: {
    Nav,
    Footer,
    Bar,
  },

  mounted() {
    this.$store.commit("setSiteLoader", true);

    setTimeout(() => {
      this.$store.commit("setSiteLoader", false);
    }, 500);
  },

  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true });
  },
};
