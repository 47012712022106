// middleware/setLanguage.js

export default function ({ app, store }) {
  // Check if running on the client side before using localStorage
  if (process.client) {
    // Retrieve the language from local storage
    const storedLanguage = localStorage
      ? localStorage.getItem("language")
      : null;

    if (storedLanguage) {
      app.i18n.setLocale(storedLanguage);
    } else {
      const defaultLanguage = app.i18n.defaultLocale;
      app.i18n.setLocale(defaultLanguage);
      localStorage.setItem("language", defaultLanguage);
    }
  }
}
