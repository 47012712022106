export const AirportCard = () => import('../../components/AirportCard.vue' /* webpackChunkName: "components/airport-card" */).then(c => wrapFunctional(c.default || c))
export const CarCard = () => import('../../components/CarCard.vue' /* webpackChunkName: "components/car-card" */).then(c => wrapFunctional(c.default || c))
export const CarCardLoader = () => import('../../components/CarCardLoader.vue' /* webpackChunkName: "components/car-card-loader" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../components/CountrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const CustomChatComponent = () => import('../../components/CustomChatComponent.vue' /* webpackChunkName: "components/custom-chat-component" */).then(c => wrapFunctional(c.default || c))
export const DateTimeInput = () => import('../../components/DateTimeInput.vue' /* webpackChunkName: "components/date-time-input" */).then(c => wrapFunctional(c.default || c))
export const LocationSearchForm = () => import('../../components/LocationSearchForm.vue' /* webpackChunkName: "components/location-search-form" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PopupForm = () => import('../../components/PopupForm.vue' /* webpackChunkName: "components/popup-form" */).then(c => wrapFunctional(c.default || c))
export const SiteLoader = () => import('../../components/SiteLoader.vue' /* webpackChunkName: "components/site-loader" */).then(c => wrapFunctional(c.default || c))
export const SocialLoginButtons = () => import('../../components/SocialLoginButtons.vue' /* webpackChunkName: "components/social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const TopBanner = () => import('../../components/TopBanner.vue' /* webpackChunkName: "components/top-banner" */).then(c => wrapFunctional(c.default || c))
export const YasminaAssistant = () => import('../../components/YasminaAssistant.vue' /* webpackChunkName: "components/yasmina-assistant" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
