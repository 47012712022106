
export default {
  computed: {
    isArabic() {
      return this.$i18n.locale === "ar"; // Adjust based on your localization setup
    },
  },
  data() {
    return {
      items: [
        {
          title: "major_airports_dubai", // Translation key
          link: "/notes/major-airports-in-dubai",
          images: ["/img1.png", "/img2.png", "/img3.png"],
        },
        {
          title: "wild_wadi_waterpark_jumeirah", // Translation key
          link: "/notes/wild-wadi-waterpark-jumeirah-dubai-al-wifaq-car-rental",
          images: ["/img4.png", "/img5.png", "/img6.png"],
        },
        {
          title: "driving_rules_dubai", // Translation key
          link: "/notes/driving-guide-in-dubai",
          images: ["/img4.png", "/img5.png", "/img6.png"],
        },
        {
          title: "salik", // Translation key
          link: "/notes/what-is-salik-guide-dubai-toll-system",
          images: ["/img4.png", "/img5.png", "/img6.png"],
        },
        {
          title: "Airports", // Translation key
          link: "/airports",
          images: ["/img4.png", "/img5.png", "/img6.png"],
        },
      ],
    };
  },
};
