// store/state.js
// Contains the initial state of your store.
export const state = () => ({
  // currentLanguage: 'en',
  // Add the new property for language
  siteSettings: null,
  siteLoader: false,
  carsList: [], // Add the new property for cars
  carDetails: null,
  isUserLoggedIn: false,
  reservationDetails: {
    name: "",
    age: "",
    phone_number: "",
    email: "",
    location: null,
    start_date: "",
    end_date: "",
    ticket: null,
    passport: null,
    license: null,
    user: "",
    car_id: "",
    amount: 0,
  },
});
