import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _50d0a46f = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _aba63ece = () => interopDefault(import('../pages/airports/index.vue' /* webpackChunkName: "pages/airports/index" */))
const _68b52fa6 = () => interopDefault(import('../pages/cars.vue' /* webpackChunkName: "pages/cars" */))
const _b143a92a = () => interopDefault(import('../pages/carsList.vue' /* webpackChunkName: "pages/carsList" */))
const _0ed6a240 = () => interopDefault(import('../pages/contactUs.vue' /* webpackChunkName: "pages/contactUs" */))
const _70887f34 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7cb012c0 = () => interopDefault(import('../pages/locations.vue' /* webpackChunkName: "pages/locations" */))
const _3a30224b = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d144d434 = () => interopDefault(import('../pages/notes/index.vue' /* webpackChunkName: "pages/notes/index" */))
const _0375c3e8 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _8e3bb72c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _25b7ceb1 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _649be794 = () => interopDefault(import('../pages/searchedCars.vue' /* webpackChunkName: "pages/searchedCars" */))
const _51a2e823 = () => interopDefault(import('../pages/socialauth.vue' /* webpackChunkName: "pages/socialauth" */))
const _1a863d69 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _3805d957 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _af13815e = () => interopDefault(import('../pages/airports/_slug.vue' /* webpackChunkName: "pages/airports/_slug" */))
const _50860087 = () => interopDefault(import('../pages/carDetails/_id.vue' /* webpackChunkName: "pages/carDetails/_id" */))
const _d4b216c4 = () => interopDefault(import('../pages/notes/_slug.vue' /* webpackChunkName: "pages/notes/_slug" */))
const _f652bb6e = () => interopDefault(import('../pages/reserve/_id.vue' /* webpackChunkName: "pages/reserve/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _50d0a46f,
    name: "about___ar"
  }, {
    path: "/airports",
    component: _aba63ece,
    name: "airports___ar"
  }, {
    path: "/cars",
    component: _68b52fa6,
    name: "cars___ar"
  }, {
    path: "/carsList",
    component: _b143a92a,
    name: "carsList___ar"
  }, {
    path: "/contactUs",
    component: _0ed6a240,
    name: "contactUs___ar"
  }, {
    path: "/en",
    component: _70887f34,
    name: "index___en"
  }, {
    path: "/locations",
    component: _7cb012c0,
    name: "locations___ar"
  }, {
    path: "/login",
    component: _3a30224b,
    name: "login___ar"
  }, {
    path: "/notes",
    component: _d144d434,
    name: "notes___ar"
  }, {
    path: "/payment",
    component: _0375c3e8,
    name: "payment___ar"
  }, {
    path: "/privacy",
    component: _8e3bb72c,
    name: "privacy___ar"
  }, {
    path: "/register",
    component: _25b7ceb1,
    name: "register___ar"
  }, {
    path: "/searchedCars",
    component: _649be794,
    name: "searchedCars___ar"
  }, {
    path: "/socialauth",
    component: _51a2e823,
    name: "socialauth___ar"
  }, {
    path: "/terms",
    component: _1a863d69,
    name: "terms___ar"
  }, {
    path: "/thanks",
    component: _3805d957,
    name: "thanks___ar"
  }, {
    path: "/en/about",
    component: _50d0a46f,
    name: "about___en"
  }, {
    path: "/en/airports",
    component: _aba63ece,
    name: "airports___en"
  }, {
    path: "/en/cars",
    component: _68b52fa6,
    name: "cars___en"
  }, {
    path: "/en/carsList",
    component: _b143a92a,
    name: "carsList___en"
  }, {
    path: "/en/contactUs",
    component: _0ed6a240,
    name: "contactUs___en"
  }, {
    path: "/en/locations",
    component: _7cb012c0,
    name: "locations___en"
  }, {
    path: "/en/login",
    component: _3a30224b,
    name: "login___en"
  }, {
    path: "/en/notes",
    component: _d144d434,
    name: "notes___en"
  }, {
    path: "/en/payment",
    component: _0375c3e8,
    name: "payment___en"
  }, {
    path: "/en/privacy",
    component: _8e3bb72c,
    name: "privacy___en"
  }, {
    path: "/en/register",
    component: _25b7ceb1,
    name: "register___en"
  }, {
    path: "/en/searchedCars",
    component: _649be794,
    name: "searchedCars___en"
  }, {
    path: "/en/socialauth",
    component: _51a2e823,
    name: "socialauth___en"
  }, {
    path: "/en/terms",
    component: _1a863d69,
    name: "terms___en"
  }, {
    path: "/en/thanks",
    component: _3805d957,
    name: "thanks___en"
  }, {
    path: "/",
    component: _70887f34,
    name: "index___ar"
  }, {
    path: "/en/airports/:slug",
    component: _af13815e,
    name: "airports-slug___en"
  }, {
    path: "/en/carDetails/:id?",
    component: _50860087,
    name: "carDetails-id___en"
  }, {
    path: "/en/notes/:slug",
    component: _d4b216c4,
    name: "notes-slug___en"
  }, {
    path: "/en/reserve/:id?",
    component: _f652bb6e,
    name: "reserve-id___en"
  }, {
    path: "/airports/:slug",
    component: _af13815e,
    name: "airports-slug___ar"
  }, {
    path: "/carDetails/:id?",
    component: _50860087,
    name: "carDetails-id___ar"
  }, {
    path: "/notes/:slug",
    component: _d4b216c4,
    name: "notes-slug___ar"
  }, {
    path: "/reserve/:id?",
    component: _f652bb6e,
    name: "reserve-id___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
