
import { mapGetters } from "vuex";
import Chat from "../../components/CustomChatComponent.vue";

export default {
  data() {
    return {
      showChatbot: false,
      chatbotData: null, // Initialize chatbotData as null
      currentLanguage: this.$i18n.locale,
      showMenu: false,
    };
  },
  components: {
    Chat,
  },
  methods: {
    switchLanguage(lang) {
      if (this.currentLanguage !== lang) {
        this.currentLanguage = lang;
        this.$i18n.setLocale(lang);
        localStorage.setItem("language", lang);
        let langIso = "ar";
        if (lang === "en") {
          langIso = "en-us";
        }
        import("quasar/lang/" + langIso)
          .then((lang) => {
            this.$q.lang.set(lang.default);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async logoutUser() {
      await this.$auth.logout();
      this.$router.push(this.localePath("/login"));
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  computed: {
    ...mapGetters(["isUserLoggedIn"]),
    languageDirection() {
      return this.currentLanguage === "ar" ? "rtl" : "ltr";
    },
  },
  async asyncData({ $content }) {
    // Fetch initial data for your page
    const pageData = await $content("fetchPageData"); // Replace with your function

    // Optionally, fetch initial data for the chatbot if needed
    const chatbotData = await $content("fetchInitialChatbotData"); // Adjust function name

    return { pageData, chatbotData };
  },
};
