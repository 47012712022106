import axios from "axios";

export const actions = {
  // fetch site settings
  async nuxtServerInit({ commit }) {
    try {
      const response = await this.$axios.get("/api/site/settings");
      commit("updateSiteSettings", response.data.data); // Update the siteSettings with response.data.data
      //return response;
    } catch (error) {
      console.error("Error fetching site settings:", error);
      //throw error;
    }
  },

  // // update language
  // async updateLanguage({ commit }, language) {
  //   try {
  //     // Update the language in Vuex store
  //     commit("updateLanguage", language);
  //     // Save the selected language to local storage
  //     localStorage.setItem("language", language);
  //     // Make a POST request to update the language on the server
  //     await axios.post("http://localhost:8000/api/site/settings", {
  //       language: language,
  //     });
  //   } catch (error) {
  //     console.error("Failed to update language:", error);
  //   }
  // },

  // fetch cars list and store in redux
  async fetchCarsList({ commit }) {
    try {
      const response = await this.$axios.get("/api/cars");
      commit("updateCarsList", response.data.data); // Assuming you have a mutation named 'updateCarsList'
      return response;
    } catch (error) {
      console.error("Error fetching cars list:", error);
      throw error;
    }
  },

  async fetchCarDetails({ commit }, carId) {
    // Fetch car details based on ID from API and commit mutation
    const carDetails = await this.$axios.get("/api/carDetails/" + carId);
    commit("setCarDetails", carDetails.data.data);
  },
  // Other actions...
};

