// store/mutations.js
export const mutations = {
  updateSiteSettings(state, data) {
    state.siteSettings = data;
  },
  // updateLanguage(state, language) {
  //   state.currentLanguage = language;
  // },

  setSiteLoader(state, value) {
    state.siteLoader = value;
  },

  updateCarsList(state, carsList) {
    state.carsList = carsList; // Assuming you have a state property named 'carsList'
  },

  setCarDetails(state, carDetails) {
    state.carDetails = carDetails;
  },

  setIsUserLoggedIn(state, value) {
    state.isUserLoggedIn = value;
  },

  setReservationDetails(state, reservationDetails) {
    state.reservationDetails = reservationDetails;
  },
  // ...
};
