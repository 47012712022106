// store/getters.js
export const getters = {
  isUserLoggedIn: (state) => {
    return state.auth.loggedIn;
  },

  loggedInUser: (state) => {
    return state.auth.user;
  },
};
