
export default {
  components: {},
  methods: {
    // scrollToTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // },
  },
};
