
const carDetails = {
  "نيسان صني": {
    keywords: [
      "صغيرة",
      "صغيره",
      "مناسبه",
      "مناسب",
      "ارخص",
      "رخيصة",
      "اقتصاديه",
      "رخيصه",
      "رخيص",
      "نيسان صني",
      "صني",
      "نيسان",
    ],
    price: "١٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ١٥٠٠ سي سي\nاللون: أبيض",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/NissanSunny-2024-1.6-litre-2tC0ESUQ7U.jpg",
    link: "https://alwifaqrentacar.com/carDetails/56",
    bookingText: "📅 احجز الآن", // Example booking text for Nissan Sunny
  },
  "مازدا 3": {
    keywords: [
      "صغيرة",
      "صغيره",
      "مناسبه",
      "مناسب",
      "ارخص",
      "رخيصة",
      "اقتصاديه",
      "رخيصه",
      "رخيص",
      "مازدا ",
      "مازدا 3",
    ],
    price: "١١٠٬٠٠٠ درهم",

    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ۱۹۹۸ سي سي\nاللون: أبيض",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/MAZDA3-2022-Four-Cylinder-P5kraHXaHY.jpg",
    link: "https://alwifaqrentacar.com/carDetails/55",
    bookingText: "📅 احجز الآن", // Example booking text for Nissan Sunny
  },
  "كيا سول": {
    keywords: [
      "صغيرة",
      "صغيره",
      "مناسبه",
      "مناسب",
      "ارخص",
      "رخيصة",
      "اقتصاديه",
      "رخيصه",
      "رخيص",
      "كيا ",
      "كيا سول",
    ],
    price: "١٢٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ١٦٠٠ سي سي\nاللون: رمادي",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/KIASOUL-2023-Four-Cylinder-V9q9WVKvbT.jpg",
    link: "https://alwifaqrentacar.com/carDetails/52",
    bookingText: "📅 احجز الآن", // Example booking text for Nissan Sunny
  },
  "هيونداي كريتا": {
    keywords: [
      "عائليه",
      "عائلية",
      "الدفع الرباعي",
      "دفع رباعي",
      "متوسطة",
      "متوسطه",
      "مناسبه",
      "مناسب",
      "معقول ",
      "معقولة",
      "متوسط ",
      "هيونداي كريتا",
      "كريتا",
      "هيونداي",
    ],
    price: "١٤٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ١٦٠٠ سي سي\nاللون: احمر",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/HyundaiCreta2024-2024-Four-Cylinder-4rijj7kWCI.jpg",
    link: "https://alwifaqrentacar.com/carDetails/54",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },

  "هيونداي توسان": {
    keywords: [
      "الدفع الرباعي",
      "دفع رباعي",
      "متوسطة",
      "متوسط",
      "سعر معقول",
      "هيونداي توسان",
      "متوسطه",
      "عائليه",
      "عائلية",
      "توسان",
      "هيونداي",
    ],
    price: "١٨٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ١٦٠٠ سي سي\nاللون: أبيض",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/HyundaiTucson2024-2024-Four-Cylinder-UVCiev4clB.jpg",
    link: "https://alwifaqrentacar.com/carDetails/49",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "هوندا اكورد كهرباء": {
    keywords: [
      "متوسطة",
      "متوسط",
      "متوسطه",
      "سعر معقول",
      "معقول",
      "هوندا",
      "هوندا اكورد",
      "اكورد",
      "كهرباء",
      "كهربائيه",
      "على الطاقه",
      "على الطاقة",
      "الطاقه",
      "الطاقة",
      "honda",
      "honda accord",
      "accord",
      "hybrid",
    ],
    price: "١٨٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٥٠٠ سي سي\nاللون: فضة",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/HONDAACCORDHybrid-2023-Hybrid-DX72TswdHk.jpg",
    link: "https://alwifaqrentacar.com/carDetails/48",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "هوندا اكورد": {
    keywords: [
      "متوسطة",
      "متوسط",
      "متوسطه",
      "سعر معقول",
      "معقول",
      "هوندا",
      "هوندا اكورد",
      "اكورد ",
      "honda",
      "honda accord",
      "accord",
    ],
    price: "١٥٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٥٠٠ سي سي\nاللون: أبيض",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/HONDAACCORD-2023-Four-Cylinder-bdIhPzoWzX.jpg",
    link: "https://alwifaqrentacar.com/carDetails/51",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "تويوتا لكزس": {
    keywords: [
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "تويوتا",
      "تويوتا لكزس",
      "لكزس",
      "لكزس ES 250",
      "تويوتا",
      "toyota",
    ],
    price: "١٨٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٥٠٠ سي سي\nاللون: أسود",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/LexusES250-2023-203horsepower-tMR5978UQd.webp",
    link: "https://alwifaqrentacar.com/carDetails/45",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "تويوتا كراون": {
    keywords: [
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "تويوتا",
      "تويوتا كراون",
      "كراون ",
      "كهرباء",
      "كهربائيه",
      "على الطاقه",
      "على الطاقة",
      "الطاقه",
      "الطاقة",
      "crown",
      "toyota crown",
      "hybrid",
      "تويوتا",
      "toyota",
    ],
    price: "٣٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٥٠٠ سي سي\nاللون: أسود",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/ToyotaCrownHybrid-2023-turbocharged2.4-UA9DwjvGk0.png",
    link: "https://alwifaqrentacar.com/carDetails/57",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },

  "بي أم دبليو": {
    keywords: [
      "الرباعي",
      "رباعي",
      "عائليه",
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "BMW",
      "BMW X3",
      "bmw",
      "عائليه",
      "عائلية",
      "بي ام دبليو",
      "بي أم دبليو",
      "بي أم دبليو X3",
    ],
    price: "٣٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٠٠٠ سي سي\nاللون: كحلي",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/BMW-X3-2023-248-horsepower-Xv2mf0wJe8.jpg",
    link: "https://alwifaqrentacar.com/carDetails/47",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "رنج روفر فيلار": {
    keywords: [
      "الرباعي",
      "رباعي",
      "عائليه",
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "range rover",
      "range rover velar",
      "velar",
      "عائليه",
      "عائلية",
      "رنج",
      "رنج روفر",
      "فيلار",
    ],
    price: "٤٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٠٠٠ سي سي\nاللون: أسود",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/RangeRoverVelar-2022-turbocharged2.0-liter-Dvmk21CZCh.png",
    link: "https://alwifaqrentacar.com/carDetails/58",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "جيب جـرانـد واجـونـيـر": {
    keywords: [
      "الرباعي",
      "رباعي",
      "عائليه",
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "jeep wagoneer",
      "jeep",
      "wagoneer",
      "عائليه",
      "عائلية",
      "جيب",
      "واجـونـيـر",
    ],
    price: "٥٥٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٣٠٠٠ سي سي\nاللون: أبيض",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/JEEPWAGONEER-2023-420horsepower-bGRMimKAN8.jpg",
    link: "https://alwifaqrentacar.com/carDetails/43",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  // "لاند روفر ديفندر": {
  //   keywords: [
  //     "الرباعي",
  //     "رباعي",
  //     "عائليه",
  //     "رفاهيه",
  //     "رفيعة",
  //     "الرفيعة",
  //     "فخمه",
  //     "فخمة",
  //     "رفاهية",
  //     "فاخره",
  //     "فاخرة",
  //     "روفر",
  //     "لاند",
  //     "ديفندر",
  //     "land",
  //     "defender",
  //     "range",
  //     "عائليه",
  //     "لاند روفر ديفندر",
  //   ],
  //   price: "٨٠٠٬٠٠٠ درهم",
  //   specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٥٠٠٠ سي سي\nاللون: ابيض",
  //   imageUrl:
  //     "https://alwifaqrentacar.com/uploads/2024/01/06/LAND%20ROVER%20DEFENDER.jpg",
  //   link: "https://alwifaqrentacar.com/products/land-rover-defender",
  //   bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  // },
  "مرسيدس بنز": {
    keywords: [
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "mercedes",
      "Mercedes Benz",
      "مرسيدس",
      "مرسيدس بنز",
    ],
    price: "٣٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٢٠٠٠ سي سي\nاللون: أسود",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/MERCEDESA220-2023-188horsepower-i4IBfPqpG2.png",
    link: "https://alwifaqrentacar.com/carDetails/46",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },
  "Mercedes s500": {
    keywords: [
      "رفاهيه",
      "رفيعة",
      "الرفيعة",
      "فخمه",
      "فخمة",
      "رفاهية",
      "فاخره",
      "فاخرة",
      "mercedes",
      "Mercedes Benz",
      "مرسيدس",
      "مرسيدس بنز",
      "mercedes benz s500",
      "500 مرسيدس بنز",
      "٥٠٠ مرسيدس بنز",
    ],
    price: "١٢٠٠٬٠٠٠ درهم",
    specifications: "ناقل الحركة: أوتوماتيك\nالمحرك: ٤٣٥ حصان\nاللون: ذهبي",
    imageUrl:
      "https://api.alwifaqrentacar.com/storage/images/cars/Mercedes-Benz-S500-2023-six-cylinderturbochargedengine-GxTYXibxZh.jpg",
    link: "https://alwifaqrentacar.com/carDetails/44",
    bookingText: "📅 احجز الآن", // Example booking text for Toyota Camry
  },

  // Add more cars with their unique details and booking texts as needed
};

const salikDetails = {
  keywords: ["سالك", "salik", "تكلفة المرور", "رسوم المرور"],
  price: "5 دراهم لكل مرور",
  website: "https://www.salik.gov.ae/",
  supportText: "لمزيد من المعلومات، يمكنك زيارة موقع سالك.",
};

const insuranceDetails = {
  "التأمين الشامل وتأمين المخالفات المسترد💰": {
    keywords: [
      "المخالفات",
      "تامين",
      "تأمين",
      "التأمين",
      "التامين",
      "التأمينات",
      "التامينات",
      "التأمين الشامل",
      "comprehensive insurance",
      "شامل",
    ],
    coverage:
      "أن نظام التأمين الشامل يقوم بتغطية جميع الحوادث بالاضافة الى التأمين ضد السرقة داخل دولة الامارات العربية المتحدة فقط حيث أن هذا التامين لايشمل التغطية في الخارج ",
    price: "قد تختلف الأسعار حسب نوع السيارة والمزيد",
    contact: "للحصول على تفاصيل أكثر، يرجى التواصل معنا.",
  },
  "التأمين الشامل - حالة الضرر ⛐": {
    keywords: ["التأمين ضد الغير", "third-party insurance", "ضد الغير"],
    coverage:
      "جميع مركباتنا مأمنه تأمينا شاملا ضد الاصطدام و الضرر و للتأمين ضد السرقة ، وهو يشمل الحوادث التي تقع داخل الدولة للمعلومات الاضافية يمكنك مراسلة قسم الدعم الفني ",
    price: "قد تختلف الأسعار حسب نوع السيارة والمزيد",
    contact: "للحصول على تفاصيل أكثر، يرجى التواصل معنا.",
  },
  "التأمين الشامل - حالة التسبب 🚨": {
    keywords: ["التأمين ضد الغير", "third-party insurance", "ضد الغير"],
    coverage:
      "اما في حالة التسبب الناتج عن سكر (الكحول) او عمل ضار أو جريمة ، يتم تحمل كافة تكاليف التصليح مع مدة أيجار وقوف السيارة داخل ورشة التصليح من قبل العميل في حالة كان هو المتسبب فيما ورد",
    price: "قد تختلف الأسعار حسب نوع السيارة والمزيد",
    contact: "للحصول على تفاصيل أكثر، يرجى التواصل معنا.",
  },

  "تأمين المخالفات المرورية 💰": {
    keywords: ["التأمين ضد الغير", "third-party insurance", "ضد الغير"],
    coverage:
      "لحظة وصولك بالسلامة إلى المطار، سيقوم أحد المختصين في خدمة العملاء بالإتصال بك وذلك لتسليم السيارة في الوقت والتاريخ المحدد وحسب الحجز المتفق عليه من قبل الطرفين ، قم بدفع مستحقات التأمين والتي هي 👈(1000)👉 درهم اماراتي الى المندوب وتأكد من أستلام وصل مختوم وموقع بالمبلغ معنون بأسم شركة الوفاق وذلك لضمان حقوقك وأسترجاع المبلغ لك بعد فترة وهي في العادة ثلاثون يوم",
    price: "قد تختلف الأسعار حسب نوع السيارة والمزيد",
    contact: "للحصول على تفاصيل أكثر، يرجى التواصل معنا.",
  },
  // Add more insurance types with their unique details and keywords as needed
};

const documentationDetails = {
  responseText: `
  - العمر: الا يقل عمر المستأجر عن 21 عاما.
- الفترة الدنيا: لا يقل عدد أيام الإيجار عن يومين.
- إذا كنت من دول مجلس التعاون الخليجي 👰:
  - المستندات المطلوبة: نسخة من بطاقة الهوية الوطنية ونسخة من رخصة القيادة.
- سائح أو من جنسية أخرى 🤴:
  - المستندات المطلوبة: نسخة من جواز السفر 🦳 ورخصة قيادة 🦳 صالحة.

  `,
  link: "https://example.com/documentation-details", // Replace with your actual link
  imageUrl: "https://example.com/documentation-image.jpg", // Replace with your actual image URL
};

const airportDetails = {
  keywords: [
    "مطار",
    "المطار",
    "مطار دبي الدولي",
    "Dubai Airport",
    "مطار دبي 1",
    "مطار دبي 2",
    "مطار دبي 3",
  ],
  responseText:
    " بالطبع،✈️ يُسعدنا أن نقدم لك خيارات متعددة لاستئجار واسترجاع سيارة في المطار، مما يتيح لك التمتع بحرية التنقل والراحة خلال رحلتك. اختر السيارة التي تناسب احتياجاتك، ثم قم بحجزها لضمان توفرها وتأكيد رحلتك بكل يسر وسهولة. . قم بإرسال المستندات المطلوبة للمتابعة لاحقا.",
  imageUrl: "/dubai airport.png",
  link: "/cars",
  bookingText: "📅 احجز الآن",
};

export default {
  components: {},
  props: {
    chatbotData: {
      type: Array,
      required: true,
      default: () => [], // Set default value to an empty array
    },
  },
  data() {
    return {
      messages: this.chatbotData,
      userInput: "",
      showCloseConfirmation: false,
      showSplineComponent: false,
      // localIsActive: true,
      shouldScrollToBottom: true,
      userInput: "",
      messages: [
        {
          sender: "bot",
          type: "text",
          text: "👋مرحبًا! كيف يمكنني مساعدتك اليوم؟",
        },
      ],
      allCars: [
        "المعروضة",
        "قائمة ",
        "المتوفره",
        "المتاحه",
        "الجميع",
        "جميع",
        "كل" /* add your other car names here */,
      ],
      greetings: [
        "مرحبا",
        "السلام عليكم",
        "هلا",
        "أهلا",
        "مرحبًا",
        "صباح الخير",
        "مساء الخير",
      ],
    };
  },

  watch: {
    // Watch for changes to the prop and update the local data property
    // isActive(newValue) {
    //   this.localIsActive = newValue;
    // },
  },

  mounted() {
    if (process.browser) {
      console.log("Client-side rendering");
    } else {
      console.log("Server-side rendering");
    }
  },
  methods: {
    isBotMessage(message) {
      // Define logic to differentiate bot and user messages
      return true;
    },
    toggleChatCloseConfirmation() {
      // Toggle the visibility of the confirmation dialog
      this.showCloseConfirmation = !this.showCloseConfirmation;
    },

    closeChat() {
      // Show the SplineComponent.vue on the first closure
      this.showSplineComponent = true;
      // Show the close confirmation dialog
      this.showCloseConfirmation = true;
      this.scrollToBottom();
    },
    confirmClose() {
      // Close the chat when confirmed
      console.log("Close chat method called!");

      // this.localIsActive = false;

      // Emit an event to notify the parent about the change
      this.$emit("close-popup");
      // Reset the flags
      this.showCloseConfirmation = false;
      // Reset the SplineComponent.vue visibility
      this.showSplineComponent = false;
    },
    cancelClose() {
      // Cancel the close operation
      // Reset the confirmation dialog
      this.showCloseConfirmation = false;
      // Reset the SplineComponent.vue visibility
      this.showSplineComponent = false;
    },
    closeSplineComponent() {
      // Close the SplineComponent.vue when the user presses its close button
      this.showSplineComponent = false;
    },

    generateCouponCode() {
      const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const randomNumber = Math.floor(Math.random() * 10000);
      const randomLetter = letters.charAt(
        Math.floor(Math.random() * letters.length)
      );
      return `${randomLetter}-${randomNumber.toString().padStart(4, "0")}`;
    },

    isBotMessage(message) {
      return message.sender === "bot";
    },

    handleUserInput() {
      const userMessage = this.userInput.trim();

      if (userMessage) {
        // Add user message to the chat
        this.messages.push({
          sender: "user",
          type: "text",
          text: userMessage,
        });

        // Add typing indicator immediately
        this.messages.push({ sender: "bot", type: "typing" });

        // Clear user input
        this.userInput = "";

        // Scroll to bottom after adding the user's message and typing indicator
        this.scrollToBottom();

        // Simulate bot response after a delay
        setTimeout(() => {
          // Remove the typing indicator after a delay
          this.messages.pop();

          // Check if the user's message includes specific keywords
          if (this.containsDiscountKeywords(userMessage)) {
            // Generate and display the discount coupon
            const couponCode = this.generateCouponCode();
            const couponMessage = {
              sender: "bot",
              type: "text",
              text: `تفضل، إليك كود الخصم الخاص بك:🎁${couponCode}. يرجى مراعاة أن هذا الكود صالح لمدة ساعتين فقط، لذا ننصح بالاستفادة منه في الفترة المحددة.`,
            };
            this.messages.push(couponMessage);
          } else {
            // Process the user's message after removing the typing indicator
            this.processUserMessage(userMessage);
          }

          // Set the flag to true so that scrolling is allowed for the next messages
          this.shouldScrollToBottom = true;
        }, 2000);
      }
    },

    containsDiscountKeywords(message) {
      const discountKeywords = [
        "تنزيلات",
        "قسيمه خصم",
        "تخفيض سعر",
        "نزيل",
        "تنزيل",
        "تخفيض",
        "قسيمة الخصم",
        "قسيمة",
        "قسيمه",
        "تقليل",
        "إهداء",
        "تخفيضات",
        "تنزيل سعر",
        "تخفيض مبلغ",
        "خفض",
        "الخصم",
        "خصم",
        "الكوبون",
        "كوبون",
        "تقليل السعر",
      ];

      return discountKeywords.some((keyword) => message.includes(keyword));
    },

    handleTypingIndicator() {
      this.messages.push({ sender: "bot", type: "typing" });
      // Set the flag to false for typing indicator messages
      this.shouldScrollToBottom = false;
    },

    processUserMessage(message) {
      let handled = false;

      // Check if the message contains keywords related to car rental duration
      if (this.containsRentalDurationKeyword(message)) {
        // Handle generic car rental request
        this.handleGenericCarRental(message);
        handled = true;
      } else if (this.allCars.some((carName) => message.includes(carName))) {
        // Add the welcome message before listing all available cars
        this.messages.push({
          sender: "bot",
          type: "text",
          text: "هذه جميع السيارات المتوفرة حاليًا:",
        });

        // Loop through each carDetails and add them to the chat
        Object.keys(carDetails).forEach((car) => {
          this.handleCarDetails(car);
        });
        handled = true;
      } else if (this.containsSalikKeyword(message)) {
        // Handle Salik-related query
        this.handleSalikDetails();
        handled = true;
      } else if (this.containsInsuranceKeyword(message)) {
        // Handle التأمينات related query
        this.handleInsuranceDetails();
        handled = true;
      } else if (this.containsDocumentationKeyword(message)) {
        // Handle documentation-related query
        this.handleDocumentationDetails();
        handled = true;
      } else {
        // Check if the message contains airport-related keywords
        if (this.containsAirportKeyword(message)) {
          // Handle the query related to renting a car from the airport
          this.handleAirportDetails();
          handled = true;
        } else {
          // Existing logic for handling specific car keywords
          Object.keys(carDetails).forEach((car) => {
            const keywords = carDetails[car].keywords;
            if (keywords.some((keyword) => message.includes(keyword))) {
              this.handleCarDetails(car);
              handled = true;
            }
          });
        }
      }

      if (!handled) {
        // If no specific keyword matched, provide a default response
        this.handleDefaultResponse();
      }

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    containsAirportKeyword(message) {
      const airportKeywords = airportDetails.keywords;
      return airportKeywords.some((keyword) => message.includes(keyword));
    },

    handleAirportDetails() {
      const airportLink = airportDetails.link;
      const bookingText = airportDetails.bookingText || "📅 احجز الآن"; // Default to '📅 احجز الآن' if not specified

      // Push the response text to messages
      this.messages.push({
        sender: "bot",
        type: "text",
        text: airportDetails.responseText,
      });

      // Push the image to messages
      this.messages.push({
        sender: "bot",
        type: "image",
        imageUrl: airportDetails.imageUrl,
      });

      // Add clickable link as a separate message
      if (airportLink) {
        // Example link message structure
        const linkMessage = {
          type: "link",
          linkId: "your-link-id", // Provide a unique ID as needed
          linkUrl: airportLink,
          linkText: bookingText, // Use the dynamically created booking text
          linkColor: "rgb(51, 102, 255)",
          linkBorderColor: "rgb(51, 102, 255)",
          linkBackgroundColor: "white",
        };

        this.messages.push({ sender: "bot", ...linkMessage });
      }

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    containsRentalDurationKeyword(message) {
      // Check if the message contains keywords related to rental duration (both written and numeric)
      const durationKeywords = ["ليوم", "يومين", "3" /* add more as needed */];
      return durationKeywords.some((keyword) => message.includes(keyword));
    },

    handleGenericCarRental(message) {
      // Modify this method based on how you want to present information about all cars for the specified duration
      // For example, you can list all cars along with their details
      this.messages.push({
        sender: "bot",
        type: "text",
        text: "هذه جميع السيارات المتاحة للإيجار للمدة التي ذكرتها:",
      });

      // Loop through each carDetails and add them to the chat
      Object.keys(carDetails).forEach((car) => {
        this.handleCarDetails(car);
      });

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    // ... (existing methods)

    containsInsuranceKeyword(message) {
      const insuranceKeywords = Object.values(insuranceDetails).flatMap(
        (insurance) => insurance.keywords
      );
      return insuranceKeywords.some((keyword) => message.includes(keyword));
    },

    handleInsuranceDetails() {
      // Modify this method based on how you want to present insurance details
      // You can customize the response based on the specific insurance type the user is asking about
      this.messages.push({
        sender: "bot",
        type: "text",
        text: "هذا التفصيل الخاص بالتأمينات لشركة الوفاق:",
      });

      // Loop through each insurance type and add details to the chat
      Object.keys(insuranceDetails).forEach((insuranceType) => {
        const details = insuranceDetails[insuranceType];
        const responseText = `${insuranceType}:\n${details.coverage}\nالسعر: ${details.price}\n${details.contact}`;
        this.messages.push({
          sender: "bot",
          type: "text",
          text: responseText,
        });
      });

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    containsSalikKeyword(message) {
      const salikKeywords = salikDetails.keywords;
      return salikKeywords.some((keyword) => message.includes(keyword));
    },

    // Add a method to handle Salik details
    handleSalikDetails() {
      const salikPrice = salikDetails.price;
      const salikWebsite = salikDetails.website;
      const salikSupportText = salikDetails.supportText;

      let responseText = `رسوم سالك: ${salikPrice}\nلزيارة موقع سالك: ${salikWebsite}\n${salikSupportText}`;
      this.messages.push({
        sender: "bot",
        type: "text",
        text: responseText,
      });

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    handleCarDetails(car) {
      const carInfo = carDetails[car];
      const carPrice = carInfo.price;
      const carSpecifications = carInfo.specifications;
      const carImage = carInfo.imageUrl;
      const carLink = carInfo.link;
      const bookingText = carInfo.bookingText || "📅 Book Now"; // Default to '📅 Book Now' if not specified

      let responseText = `Car: ${car}\nPrice: ${carPrice}\nSpecifications: ${carSpecifications}`;
      this.messages.push({
        sender: "bot",
        type: "text",
        text: responseText,
      });
      this.messages.push({
        sender: "bot",
        type: "image",
        imageUrl: carImage,
      });

      // Add clickable link as a separate message
      if (carLink) {
        // Example link message structure
        const linkMessage = {
          type: "link",
          linkId: "your-link-id", // Provide a unique ID as needed
          linkUrl: carLink,
          linkText: bookingText, // Use the dynamically created booking text
          linkColor: "rgb(51, 102, 255)",
          linkBorderColor: "rgb(51, 102, 255)",
          linkBackgroundColor: "white",
        };

        this.messages.push({ sender: "bot", ...linkMessage });
      }

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    containsDocumentationKeyword(message) {
      const documentationKeywords = [
        "المستندات المهمة للإيجار",
        "النقدي",
        "المطلوب",
        "المطلوبه",
        "الملفات",
        "العمر",
        "عمر",
        "الشروط",
        "شروط",
        "دفع",
        "الوثائق",
        "الكاش",
        "نقدي",
        "نقدنا",
        "الفيزا",
        "كاش",
        "الدفع",
        "فيزا",
        "متطلبات الإيجار",
        "عقد الايجار",
        "كيفية استئجار سيارة",
        "الأوراق المطلوبة للإيجار",
        "شروط الإيجار",
        "المستندات اللازمة للإيجار في دبي",
        "أوراق استئجار السيارات",
        "متطلبات تأجير السيارات في دبي",
        "المستندات المطلوبة لاستئجار سيارة في الإمارات",
        "الاوراق",
        "المستندات",
        "المستمسكات",
        "الأوراق",
        "أين يمكنني العثور على متطلبات الإيجار في دبي؟",
      ];
      return documentationKeywords.some((keyword) => message.includes(keyword));
    },

    handleDocumentationDetails() {
      // Modify this method based on how you want to present documentation details
      this.messages.push({
        sender: "bot",
        type: "text",
        text: documentationDetails.responseText,
      });

      // Set the flag to true so that scrolling is allowed for the next messages
      this.shouldScrollToBottom = true;
    },

    handleDefaultResponse() {
      // Default response for other user inputs
      // Customize this response based on your requirements
      this.messages.push({
        sender: "bot",
        type: "text",
        text: "عذرا، لم أجد المعلومات المطلوبة. هل ترغب في التحدث مع وكيل حقيقي؟",
      });

      // Add WhatsApp link to talk to a real agent
      const whatsappLink =
        "https://api.whatsapp.com/send?phone=971504608424&text=";
      this.messages.push({
        sender: "bot",
        type: "link",
        linkText: "تحدث إلى وكيل حقيقي",
        linkUrl: whatsappLink,
      });

      this.scrollToBottom();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messagesContainer;
        if (this.shouldScrollToBottom) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    },
  },
};
